<template>
  <div class="c-base-layout">
    <div class="menu-list-wrap">
      <ul class="menu-list g-content">
        <li class="menu-list__item" v-for="item of myMusicTabs" :key="item.routeName" @click="toPage(item.routeName)">
          <p class="menu-list__item__text" :class="{ 'menu-list__item__text--active': route.name === item.routeName }">
            {{ item.label }}
          </p>

          <img v-show="route.name === item.routeName" class="decorate-img" src="@/assets/icon/highlight_2.png" />
        </li>
      </ul>
    </div>

    <div class="c-base-layout__content">
      <div class="hide-mobile" v-if="isHideInMobile">
        <div class="hide-mobile__content">
          <p class="warning">手機不支援此頁面</p>
        </div>
      </div>
      <div class="main" v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { mobileTabs, pcTabs } from '@/config/myMusicBaseLayoutConfig';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'BaseLayout',
  components: {},
  setup() {
    const siteStore = useSiteStore();
    const route = useRoute();
    const router = useRouter();
    const toPage = (name) => {
      router.push({ name });
    };

    const myMusicTabs = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcTabs;
      }

      return mobileTabs;
    });

    const isHideInMobile = computed(() => {
      // sm-pc 所有都出現
      if (siteStore.deviceType.includes('sm-pc')) {
        return false;
      }

      // sm-pc 以下只有 所有歌單 和 我的筆記
      if (['my-music-playlists', 'my-music-my-note'].includes(route.name)) {
        return false;
      }

      // 剩下顯示提示
      return true;
    });

    return {
      route,
      myMusicTabs,
      toPage,
      isHideInMobile,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-base-layout {
  background-color: $c-assist1;
  // height: 100%;
  // @include padding(0 0 90px);
  @include padding(60px 0 90px);
  @include min-h100();

  &__content {
  }
}

.menu-list-wrap {
  background-color: $c-white;
}

.menu-list {
  @include flex();
  padding-top: 20px;
  padding-bottom: 20px;

  &__item {
    position: relative;

    & + & {
      margin-left: 30px;
    }

    &__text {
      @include font-style($c-assist3, 20, normal, 1px, 28px);
      cursor: pointer;

      &--active {
        color: $c-black;
        font-weight: bold;
      }
    }
  }
}

.decorate-img {
  @include position(br, -2px, -5px);
}

.hide-mobile {
  margin-top: 30px;

  &__content {
    @include flex(center, center, column);
  }
}

.warning {
  @include font-style($c-main, 16);
  margin-bottom: 30px;
}

@media screen and (min-width: $sm-pc) {
  .c-base-layout {
    padding-top: 80px;
  }
  .menu-list {
    @include padding(55px 0);
  }
}
</style>
