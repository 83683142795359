export const mobileTabs = [
  {
    label: '所有歌單',
    routeName: 'my-music-playlists',
    icon: 'playlists',
  },
  {
    label: '我的筆記',
    routeName: 'my-music-my-note',
    icon: 'my-note',
  },

];

export const pcTabs = [
  {
    label: '所有歌單',
    routeName: 'my-music-playlists',
    icon: 'playlists',
  },
  {
    label: '搜尋紀錄',
    routeName: 'my-music-search-history',
    icon: 'search-history',
  },
  {
    label: '播放紀錄',
    routeName: 'my-music-play-history',
    icon: 'play-history',
  },
  {
    label: '下載紀錄',
    routeName: 'my-music-download-history',
    icon: 'download-history',
  },
  {
    label: '我的筆記',
    routeName: 'my-music-my-note',
    icon: 'my-note',
  },
  {
    label: '影音結合體會',
    routeName: 'my-music-preview-tool',
    icon: 'preview-tool',
  },
];
