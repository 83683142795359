<template>

  <div class="v-my-music-preview-tool">
    <base-layout>
      <!-- <div> -->
      <div class="preivew-content">
        <div class="preivew-content__upload-video">
          <mi-button>
            <img class="upload-icon" src="@/assets/myMusic/icon_video_upload_white.svg" />
            <span class="upload-text">Upload-video</span>
          </mi-button>
        </div>

        <div class="preivew-content__preview-options">
          <div class="filter">
            <p class="filter__title">選擇預覽曲目</p>

            <div class="filter__form">
              <div class="filter__form__select">
                <mi-select v-model:value="playlistName" :options="playlistOptions" label="" isPreviewTool  width="100%"/>
              </div>
              <div class="filter__form__keyword">
                <div class="input-box">
                  <input class="input-box__field" placeholder="搜尋曲目名稱" />
                  <img class="input-box__icon" src="@/assets/myMusic/icon_search.svg" />
                </div>
              </div>
            </div>
          </div>

          <div class="playlist-content">
            <ul class="playlist-list">
              <li class="playlist-list__item" v-for="i of 10" :key="i">
                <div class="item-row">
                  <img class="item-row__play-icon" src="@/assets/myMusic/icon_video_play.svg" />
                  <div class="item-row__cover-name">
                    <img class="item-row__cover-name__cover" src="@/assets/explore/playlists_img.jpg" />
                    <p class="item-row__cover-name__name">Bags of Fun</p>
                  </div>
                  <div class="item-row__time">03:15</div>
                  <div class="item-row__id">MAT3-5-5</div>
                  <div class="item-row__action">
                    <img class="item-row__action__add" src="@/assets/myMusic/icon_video_cancel.svg" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="control-wrap">

        <p class="notice">
          <img class="notice__icon" src="@/assets/myMusic/icon_info.svg" />
          <span class="notice__text">點擊進度條跳至該播放位置。</span>

        </p>
        <!-- video -->
        <div class="control-row">
          <div class="control-row__icon">
            <img class="control-row__action-icon__upload" src="@/assets/myMusic/icon_video.svg" />
          </div>

          <div class="control-row__name">影片檔案</div>
          <div class="control-row__action-icon">
            <img class="control-row__action-icon__upload" src="@/assets/myMusic/icon_video_upload.svg" />
            <img class="control-row__action-icon__play" src="@/assets/myMusic/icon_video_play.svg" />
          </div>
          <div class="control-row__progress">
            <div class="progress">
              <p class="progress__time progress__time--current-time">01:25</p>
              <div class="progress__tube">
                <progress-bar height="5" />
              </div>
              <p class="progress__time progress__time--total-time">04:56</p>
            </div>
          </div>
        </div>

        <!-- music -->
        <div class="control-row">
          <div class="control-row__icon">
            <img class="control-row__action-icon__upload" src="@/assets/myMusic/icon_video_music_grey.svg" />
          </div>

          <div class="control-row__name">
            <p class="control-row__name__title">Home sweet home</p>
            <p class="control-row__name__id">MAT305-5</p>
          </div>
          <div class="control-row__action-icon control-row__action-icon--music">
            <img class="control-row__action-icon__play" src="@/assets/myMusic/icon_video_play.svg" />
          </div>
          <div class="control-row__progress">
            <div class="progress">
              <!-- TOFIX 要跟音樂播放器的時間軸不同 -->
              <music-progress styleType="preview" :ml="false" />
            </div>
          </div>
        </div>
      </div>

      <div class="btn-wrap">
        <mi-button>
          <span class="preview-text">同步預覽</span>
          <img class="preview-icon" src="@/assets/myMusic/icon_video_play_white.svg" />
        </mi-button>
      </div>
      <!-- </div> -->
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BaseLayout from '@/components/MyMusic/BaseLayout.vue';
import MiButton from '@/components/Global/MiButton.vue';
import MiSelect from '@/components/Global/Select/index.vue';
import ProgressBar from '@/components/Global/ProgressBar.vue';
import MusicProgress from '@/components/Global/MusicPlayer/MusicProgress.vue';

export default defineComponent({
  name: 'MyMusicPreviewTool',
  components: {
    BaseLayout,
    MiButton,
    MiSelect,
    ProgressBar,
    MusicProgress,
  },
  setup() {
    const playlistName = ref('All Playlist');
    const playlistOptions = [
      {
        label: 'All Playlist',
        value: 'All Playlist',
      },
      {
        label: 'a2',
        value: 'a2',
      },
    ];
    return {
      playlistName,
      playlistOptions,
    };
  },
});
</script>
<style lang="scss" scoped>
.preivew-content {
  @include max-width(1400);
  margin-top: 70px;
  @include flex();
  height: 480px;
  box-shadow: 0px 0px 10px rgba($c-black, 0.1);

  &__upload-video {
    flex: none;
    height: 100%;
    width: 50%;
    @include flex(center);
    background-color: $c-white;
  }

  &__preview-options {
    @include flex(flex-start, flex-start, column);
    height: 100%;
    flex: none;
    width: 50%;
    background-color: $c-white3;
  }
}

.upload-icon {
  width: 25px;
}

.upload-text {
  margin-left: 5px;
}

.filter {
  @include padding(16px 30px 0px 54px);
  width: 100%;
  flex: none;
  height: auto;

  &__title {
    @include font-style($c-black, 18, normal, 0.9px, 24px);
  }

  &__form {
    @include flex();
    margin-top: 24px;

    &__select {
      flex: 3;
    }

    &__keyword {
      margin-left: 20px;
      flex: 4;
    }
  }
}

.input-box {
  @include padding(14px 24px);
  @include flex(space-between);
  height: 50px;
  border-radius: 25px;
  border: 1px solid $c-assist3;

  &__field {
    flex: 1;
    background-color: transparent;
    border: 0px transparent;
    outline: 0px transparent;
    @include font-style($c-assist4, 16, normal, 0.8px, 22px);
  }

  &__icon {
    flex: none;
    width: 20px;
  }
}

.playlist-content {
  margin-top: 24px;
  flex: 1;
  width: 100%;
  overflow: auto;
}

.playlist-list {
  &__item {
  }
}

.item-row {
  @include flex();
  @include padding(13px 30px 13px 24px);
  width: 100%;

  &:hover {
    background-color: $c-assist2;
  }

  &__play-icon {
    width: 13px;
  }
  &__cover-name {
    flex: 1;
    @include flex();
    margin-left: 18px;

    &__cover {
      width: 30px;
      height: 30px;
    }

    &__name {
      margin-left: 16px;
      @include font-style($c-black, 16, bold, 0.8px, 26px);
    }
  }
  &__time {
    @include font-style($c-assist3, 14, normal, 0px, 26px);
  }
  &__id {
    margin-left: 32px;
    @include font-style($c-assist3, 14, normal, 0px, 26px);
  }
  &__action {
    margin-left: 32px;
    width: 30px;

    &__add {
      width: 100%;
      vertical-align: bottom;
    }
  }
}

.control-wrap {
  @include max-width(1400);
  margin-top: 46px;
}

.info {
  @include flex();
  width: 100%;

  &__icon {
    flex: none;
    width: 16px;

    vertical-align: bottom;
  }

  &__text {
    flex: 1;
    margin-left: 8px;
    @include font-style($c-assist4, 16, normal, 0, 26px);
  }
}

.control-row {
  @include flex();
  @include padding(40px 40px 40px 24px);
  border-bottom: $c-assist7 1px solid;

  &__icon {
    width: 30px;
  }

  &__name {
    margin-left: 20px;
    @include font-style($c-black, 16, bold, 0.8px, 26px);
    width: 200px;

    &__id {
      @include font-style($c-assist14, 14, bold, 0.7px, 26px);
    }
  }

  &__action-icon {
    width: 65px;
    margin-left: 34px;
    @include flex(space-between);

    &--music {
      @include flex(flex-end);
    }

    &__upload {
      vertical-align: bottom;
      display: inline-block;
    }

    &__play {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  &__progress {
    flex: 1;
    margin-left: 34px;
  }
}

.progress {
  @include flex();

  &__tube {
    flex: 1;
  }

  &__time {
    @include font-style($c-assist4, 14, 600, 0.7px, 18px);
    flex: none;

    &--current-time {
      margin-right: 30px;
    }

    &--total-time {
      margin-left: 30px;
    }
  }
}

.btn-wrap {
  margin-top: 37px;
  @include flex(center);
}

.preview-icon {
  width: 14px;
  margin-left: 10px;
}

.notice {
  @include flex();

  &__icon {

  }

  &__text {
    margin-left: 8px;
    @include font-style($c-assist4, 16, 400, 0, 26px);
  }
}
</style>
